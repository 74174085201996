import isEmpty from 'lodash/isEmpty';

function getMedEntitlement(context) {
    const SessionStore = context.getStore('Session');
    const npi = SessionStore.getNpi();

    const hasEntitlement = SessionStore.hasEntitlement('medications');

    return hasEntitlement && !isEmpty(npi);
}

export default getMedEntitlement;
